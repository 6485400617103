/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  AllPlugins,
  PluginInstance,
  PluginsModule,
} from '@kiroboio/fct-core';
import {
  Erc20Airdrop,
  Erc20Approvals,
  Erc20MonoTransfers,
  Erc20Transfers,
  EthTransfers,
  MultiAdd,
  MultiBalance,
  MultiDivider,
  MultiMultiplier,
  MultiSubtract,
  MultiGetOffchainData,
  getPluginsFromABI,
  ApprovedSwap,
  getPlugins,
  UnwrapAndSendEth,
} from '@kiroboio/fct-core';
import type { TokenItemType } from '@kiroboio/fct-sdk';
import type { ApiService } from '@kiroboio/fct-service';
import type { AbiItem } from 'ethereum-abi-types-generator';
import { ethers } from 'ethers';
import _ from 'lodash';

import { wait } from '~/lib/utils/wait';
import type { ChainIds, ExcitingChainIds } from '~/lib/wagmi';
import { isPluginNode } from '../Nodes/nodes';
import {
  ignoreByNamePlugins,
  ignorePlugins,
  ignoreProtocols,
  pickByNamePlugins,
  pickPluginsFromIngonredProtocols,
  supportedProtocols,
} from './pluginListsConfig';
import type { FCTNode } from '@kiroboio/fct-builder';

const multiCallPlugins = [
  MultiBalance,
  EthTransfers,
  Erc20Airdrop,
  Erc20Approvals,
  Erc20MonoTransfers,
  Erc20Transfers,
  MultiAdd,
  MultiSubtract,
  MultiDivider,
  MultiMultiplier,
  MultiGetOffchainData,
  ApprovedSwap,
  UnwrapAndSendEth,
];

export const DYNAMIC_INPUTS_FOR_MULTI_CALLS_PLUGINS: Record<
  string,
  { inputs: string[]; outputs: string[] }
> = {
  erc20airdrop: {
    inputs: ['recipient'],
    outputs: ['success'],
  },
  multibalance: {
    inputs: ['token', 'account'],
    outputs: ['balance'],
  },
  erc20monotransfers: {
    inputs: ['to', 'amount'],
    outputs: ['success'],
  },
  erc20transfers: {
    inputs: ['token', 'from', 'to', 'amount'],
    outputs: ['success'],
  },
  erc20approvals: {
    inputs: ['token', 'spender', 'amount'],
    outputs: ['success'],
  },
};

export const isMultiCallNode = (node: FCTNode): node is FCTNode => {
  if (!isPluginNode(node)) {
    console.log('isMultiCallNode: not a plugin node');
    return false;
  }
  return (node.data.protocol as string) === 'KIROBO_MULTICALL';
};

export const MULTI_CALL_IO_KEYS = new Set(
  Object.values(DYNAMIC_INPUTS_FOR_MULTI_CALLS_PLUGINS)
    .map((plugin) => [...plugin.inputs, ...plugin.outputs])
    .flat()
);

const MULTICALL_INPUT_KEY_REG = new RegExp(
  `(${[...MULTI_CALL_IO_KEYS].join('|')})_(\\d+)`
);

export const breakDownMultiInputKey = (key: string) => {
  // recipient_0 -> [recipient_0, recipient, 0]
  return key.match(MULTICALL_INPUT_KEY_REG) || [];
};

export const isMultiCallInputKey = (key: string) => {
  return MULTICALL_INPUT_KEY_REG.test(key);
};

export type PluginType =
  | 'DEFAULT'
  | 'CUSTOM'
  | 'EDITED'
  | 'MODULE'
  | 'VARIABLE'
  | 'MULTICALL';
export interface ICustomPluginConfig {
  abi: AbiItem[];
  chainId?: string | number;
  protocol: string;
  contractAddress: string;
  address: string;
  id?: string;
}

export type Multicall = (typeof multiCallPlugins)[number];

export interface IUIPlugin {
  instance: PluginInstance;
  details: { plugin: AllPlugins };
  type: PluginType;
  multicall?: Multicall;
  id?: string;
}
export interface IUICustomPlugin extends Omit<IUIPlugin, 'id'> {
  id: string;
}

export interface IUIPluginsModule {
  type: PluginType;
  id: string;
  moduleInstance: PluginsModule;
  instance: PluginsModule;
}

export type IPluginVariable = {
  name: string;
  type: string;
  value: {
    type: string;
    id: string;
  };
};

type OnChainTokenDetailsType = TokenItemType;
// TODO: KIRO addresses of 42161 are fake because they are not deployed yet
export const KIRO = {
  '1': '0xB1191F691A355b43542Bea9B8847bc73e7Abb137',
  '10': '0xa82a423671379fD93f78eA4A37ABA73C019C6D3C',
  '8453': '0xba232b47a7dDFCCc221916cf08Da03a4973D3A1D',
  '42161': '0xba232b47a7dDFCCc221916cf08Da03a4973D3A1D',
  '11155111': '0x85a39f55778D98e81237e917d1f7fb4b74Bdc2Af',
};

export const UNI = {
  '1': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  '10': '0x6fd9d7AD17242c41f7131d257212c54A0e816691',
  '8453': '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0', // fake address, no address currently
  '42161': '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
  '11155111': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
};

export const WETH = {
  '1': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '10': '0x4200000000000000000000000000000000000006',
  '8453': '0x4200000000000000000000000000000000000006',
  '42161': '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  '11155111': '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
};

export const USDC = {
  '1': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  '10': '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  '8453': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  '42161': '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  '11155111': '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
};

export const TEST_RPCs = {
  '1': 'https://mainnet.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77',
  '10': 'https://optimism-mainnet.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77',
  '8453': 'https://base-mainnet.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77',
  '42161':
    'https://arbitrum-mainnet.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77',
  '11155111': 'https://sepolia.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77',
  notSupportedChainId: `https://mainnet.infura.io/v3/14c73ecdbcaa464585aa7c438fdf6a77`,
};

export class SupportedPlugins {
  public static pluginsModule: PluginsModule | undefined;

  public static chainId: ChainIds = '1';

  public static start = ({
    chainId,
    account,
    testProviderService,
  }: {
    chainId: ChainIds;
    account: string;
    testProviderService: ApiService;
  }) => {
    this.init({ chainId, account, testProviderService });
  };

  public static stop = () => {};

  public static isCustomPluginsActive = true;

  public static isModulesActive =
    Boolean(process.env.NEXT_PUBLIC_USE_MODULES) &&
    process.env.NEXT_PUBLIC_USE_MODULES !== 'false';

  public static pluginService: ApiService | undefined;

  public static moduleService: ApiService | undefined;

  public static testProviderService: ApiService | undefined;

  public static get predefined() {
    return this._predefined;
  }

  public static get custom() {
    return this._custom;
  }

  public static get multicallPlugins() {
    return this._multicallPlugins;
  }

  public static get all() {
    return this.predefined
      .concat(...this.custom)
      .concat(...this._multicallPlugins);
  }

  public static get groupedPlugins() {
    return _.groupBy(this.all, 'instance.protocol') as unknown as {
      [key: string]: IUIPlugin[];
    };
  }

  public static subscribe = ({
    callback,
    id,
  }: {
    callback: (p: typeof SupportedPlugins) => void;
    id: string;
  }) => {
    this.observers.set(id, callback);
  };

  public static unsubscribe = ({ id }: { id: string }) => {
    this.observers.delete(id);
  };

  public static setDefaultPlugins({ chainId }: { chainId: ChainIds }) {
    if (chainId === '0') return;
    const allPlugins = getPlugins({});
    const plugins = allPlugins
      .map((p) => {
        try {
          return {
            instance: new p.plugin({
              chainId: chainId as any,
            }),
            details: p,
            type: 'DEFAULT',
            id: undefined,
          };
        } catch (e) {
          console.error(`Error with plugin ${p.name}`);
          return undefined;
        }
      })
      .filter(Boolean) as unknown as IUIPlugin[];

    this._predefined = plugins.filter((p) => {
      const isPicked = _.find(
        pickPluginsFromIngonredProtocols,
        _.pick(p.instance, ['protocol', 'method'])
      );
      const isIgnoreByName = _.find(
        ignoreByNamePlugins,
        _.pick(p.instance, ['protocol', 'name'])
      ) as (typeof ignoreByNamePlugins)[number];
      if (isIgnoreByName && isIgnoreByName?.chainId.includes(chainId))
        return false;

      const isPickedByName = _.find(
        pickByNamePlugins,
        _.pick(p.instance, ['protocol', 'name'])
      ) as (typeof pickByNamePlugins)[number];
      if (isPickedByName && !isPickedByName?.chainId.includes(chainId))
        return false;
      if (isPickedByName || isPicked) return true;

      const isIgnored = _.find(
        ignorePlugins,
        _.pick(p.instance, ['protocol', 'method'])
      );
      return (
        !isIgnored &&
        supportedProtocols.includes(p.instance.protocol) &&
        !ignoreProtocols[chainId]?.includes(p.instance.protocol)
      );
    }) as unknown as IUIPlugin[][];
  }

  public static async addConfig(config: ICustomPluginConfig) {
    const plugins = this.configToUIPlugins(config);
    if (!plugins) return;
    this._custom.push(plugins);
    this.onUpdate();
  }

  public static async removeConfig(config: ICustomPluginConfig) {
    this._custom = this._custom.filter((c) => config.id !== c[0].id);
    this.onUpdate();
  }

  public static getCustomPlugins(configs: ICustomPluginConfig[] = []) {
    if (!this.isCustomPluginsActive) return;
    return configs.map(this.configToUIPlugins) as IUICustomPlugin[][];
  }

  public static variables = [
    {
      name: 'Block Timestamp',
      type: 'uint256',
      value: {
        type: 'global',
        id: 'blockTimestamp',
      },
    },
    {
      name: 'Block Number',
      type: 'uint256',
      value: { type: 'global', id: 'blockNumber' },
    },
    {
      name: 'Gas Price',
      type: 'uint256',
      value: { type: 'global', id: 'gasPrice' },
    },
    {
      name: 'Miner Address',
      type: 'address',
      value: { type: 'global', id: 'minerAddress' },
    },
    {
      name: 'Solver Address',
      type: 'address',
      value: { type: 'global', id: 'originAddress' },
    },
    {
      name: 'Intent Hash',
      type: 'bytes32',
      value: { type: 'global', id: 'flowHash' },
    },
    {
      name: 'Address Zero',
      type: 'address',
      value: { type: 'constants', id: 'addressZero' },
    },
    {
      name: 'Hash Zero',
      type: 'bytes32',
      value: { type: 'constants', id: 'hashZero' },
    },
    {
      name: 'Max Value',
      type: 'uint256',
      value: { type: 'constants', id: 'maxUint' },
    },
    {
      name: 'Max Repeats',
      type: 'uint256',
      value: { type: 'global', id: 'fctMaxRepeats' },
    },
    {
      name: 'Repeats Left',
      type: 'uint256',
      value: { type: 'global', id: 'fctRepeatsLeft' },
    },
  ] as const;

  public static externalVariables: {
    name: string;
    type: ('uint256' | 'bytes32' | 'address' | 'address[]')[];
    value: { type: 'external'; id: number };
  }[] = [] as const;

  public static setExternalVariables = () => {
    this.externalVariables = [];
    for (let i = 0; i < 16; i += 1) {
      this.externalVariables.push({
        name: `Variable #${i + 1}`,
        type: ['address', 'uint256', 'bytes32', 'address[]'],
        value: { type: 'external', id: i },
      });
    }
  };

  public static set variable(variable: IPluginVariable | undefined) {
    this._variable = variable;
    this.onUpdate();
  }

  public static get variable() {
    return this._variable;
  }

  public static get testProvider() {
    return {
      provider: this._provider,
      signer: this._signer,
      walletSigner: this._walletSigner,
    };
  }

  public static setTestProvider = async ({
    from,
    wallet,
    customNativeVaultAmount,
    customNativeWalletAmount,
  }: {
    from: string;
    wallet: string;
    customNativeVaultAmount?: string;
    customNativeWalletAmount?: string;
  }) => {
    // console.log('ddd customNativeVaultAmount', customNativeVaultAmount);
    // console.log('ddd customNativeWalletAmount', customNativeWalletAmount);
    if (!this.testProviderService) return;
    if (!from) return;
    this.getOutputValuesInProgress = true;
    const testProvider = (await this.testProviderService.create({
      cmd: 'getProvider',
    })) as { url: string };
    if (!testProvider.url) {
      this.getOutputValuesInProgress = false;
      return;
    }
    this._provider = new ethers.providers.JsonRpcProvider(testProvider.url);
    this._signer = this._provider.getSigner(from);

    this._walletSigner = this._provider.getSigner(wallet);

    if (customNativeWalletAmount) {
      const hexifydWalletValue = ethers.utils.hexValue(
        ethers.utils.parseUnits(customNativeWalletAmount).toHexString()
      );
      await this._provider.send('tenderly_setBalance', [
        wallet,
        hexifydWalletValue,
      ]);
    }

    if (customNativeVaultAmount) {
      const hexifydVaultValue = ethers.utils.hexValue(
        ethers.utils.parseUnits(customNativeVaultAmount).toHexString()
      );
      await this._provider.send('tenderly_setBalance', [
        from,
        hexifydVaultValue,
      ]);
    }

    return true;
  };

  public static revertTestProvider = async () => {
    this.getOutputValuesInProgress = false;
  };

  public static supportedTokens: {
    value: string;
    name: string;
    address: string;
    decimals: number;
    symbol: string;
    chainId: number;
    logoURI?: string | undefined;
    default?: boolean | undefined;
  }[] = [];

  public static sortedTokens: {
    [key: string]: { [key: string]: OnChainTokenDetailsType[] };
  } = {};

  public static getOutputValuesInProgress: boolean | undefined;

  private static _provider: ethers.providers.JsonRpcProvider | undefined;

  private static _signer: ethers.providers.JsonRpcSigner | undefined;

  private static _walletSigner: ethers.providers.JsonRpcSigner | undefined;

  private static init = async ({
    chainId,
    account,
    testProviderService,
  }: {
    chainId: ChainIds;
    account: string;
    testProviderService: ApiService;
  }) => {
    if (chainId === '0') return;
    this.setExternalVariables();
    this.chainId = chainId;
    this.stop();
    SupportedPlugins.testProviderService = testProviderService;
    try {
      SupportedPlugins.setMulticallPlugins();
    } catch (e) {
      console.error({ SuppirtedPluginsInitError: e });
      await wait(50000);
      this.init({ chainId, account, testProviderService });
    }
  };

  private static configToUIPlugins = (config: ICustomPluginConfig) => {
    const chainId = String(config.chainId);
    if (!chainId || chainId === '0') return;

    const supportedChainId = chainId as Omit<ExcitingChainIds, '10'>;
    const { plugins } = getPluginsFromABI({
      ...config,
      chainId: supportedChainId as any,
    });
    return plugins.map((p) => ({
      instance: new p.plugin({ chainId: supportedChainId as any }),
      details: p,
      type: 'CUSTOM',
      id: config.id,
    })) as unknown as IUICustomPlugin[];
  };

  private static setMulticallPlugins() {
    const { chainId } = this;
    if (chainId === '0') {
      return;
    }
    this._multicallPlugins = [
      multiCallPlugins.map((Plugin) => {
        const multiPluginInstance = new Plugin({
          chainId: chainId as any,
        });
        return {
          instance: multiPluginInstance,
          details: { plugin: Plugin, description: 'multicall_plugin' },
          type: 'MULTICALL',
          multicall: Plugin,
          id: undefined,
        };
      }),
    ] as unknown as IUIPlugin[][];
  }

  private static _multicallPlugins: IUIPlugin[][] = [];

  private static _variable: IPluginVariable | undefined;

  public static _custom: IUICustomPlugin[][] = [];

  private static _predefined: IUIPlugin[][] = [];

  private static observers: Map<string, (p: typeof SupportedPlugins) => void> =
    new Map();

  public static onUpdate = () => {
    this.observers.forEach((observe) => {
      observe(this);
    });
  };
}
